export default theme => ({
  content: {
    minWidth: 315,
    paddingLeft: 0,
    borderRadius: 0,
    height: 'inherit',
  },

  mobileContent: {
    paddingLeft: 20,
    height: '100%',
  },

  menu: {
    padding: 0,
    '&:last-child': {
      marginBottom: 90,
    },
  },

  name: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },

  menuItem: {
    padding: '0 5px',
    display: 'list-item',
  },

  submenu: {
    listStyle: 'none',
    padding: `0 0 ${theme.spacing(1)} 30px`,
    margin: 0,
  },

  searchItem: {
    outline: 'none',
    padding: 0,
    borderBottom: `2px solid ${theme.palette.border}`,
  },

  searchInput: {
    height: 48,
    padding: '0 17px',
  },

  divider: {
    borderBottom: `1px solid ${theme.palette.delimiter}`,
  },

  emptyItem: {
    outline: 'none',
    padding: 0,
  },

  filtersMenu: {
    boxSizing: 'border-box',
    '&:active': {
      border: 'none',
    },
  },

  filtersMobileMenu: {
    boxSizing: 'border-box',
    height: 50,
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    border: `1px solid ${theme.palette.filter.background.mobile}`,
    borderRadius: 8,
    marginLeft: 5,
    '&:active': {
      border: 'none',
    },
    [theme.breakpoints.up('postmd')]: {
      borderRadius: 4,
    },
  },

  filtersActive: {
    backgroundColor: theme.palette.filter.iconActive,
    border: `1px solid ${theme.palette.filter.iconActive}`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.filter.iconActive,
    },
  },

  filtersCounter: {
    color: theme.palette.filter.counterText,
    backgroundColor: theme.palette.filter.background.counter,
    width: 15,
    height: 15,
    zIndex: 10000,
    borderRadius: 4,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
    padding: 3,
    boxSizing: 'border-box',
    margin: 0,
    lineHeight: theme.typography.pxToRem(10),
  },

  counterPosition: {
    position: 'absolute',
    top: -4,
    right: 38,
  },

  hidden: {
    display: 'none',
  },

  filterHeader: {
    borderBottom: `1px solid ${theme.palette.borderLightGray}`,
    height: 70,
    padding: '38px 0 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& h1': {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: theme.palette.text.primary,
      margin: '0 10px 0 0',
    },
  },

  filterContainer: {
    minWidth: 315,
    minHeight: 70,
    borderBottom: `1px solid ${theme.palette.borderLightGray}`,
    maxHeight: 'calc(100vh - 225px)',
    overflow: 'scroll',
    display: 'list-item',
    padding: 0,
  },

  filterLabel: {
    height: 70,
    width: 'calc(100% - 20px)',
    padding: 0,
    '& h2': {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: 700,
      color: theme.palette.text.primary,
      margin: 0,
    },
    '& * span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },

  expand: {
    color: theme.palette.text.primary,
  },

  filterForm: {
    borderRadius: 8,
    height: 65,
    margin: '0 5px',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
  },

  cancel: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.lightGray,
  },

  apply: {
    color: theme.palette.white,
    background: `linear-gradient(139.79deg, ${theme.palette.CHANGEME[0]} 0%, ${theme.palette.blue50} 100%)`,
  },

  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    textAlign: 'center',
    padding: '10px 0',
    width: '100%',
    height: 85,
    background: theme.palette.white,
    boxShadow: `0 -2px 8px 0 ${theme.palette.CHANGEME[47]}`,
    display: 'list-item',
    [theme.breakpoints.up(500)]: {
      maxWidth: 450,
    },
  },

  clearFilter: {
    color: theme.palette.filter.background.counter,
    position: 'absolute',
    right: 15,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
  },

  emptyState: {
    padding: 35,
    width: '100%',
  },
});
