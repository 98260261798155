import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, List, ListItem } from '@material-ui/core';
import cn from 'classnames';
import { isEmpty } from 'ramda';
import { flattenTree, compactParams } from 'utils/Utils';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { FiltersInput, FiltersMenu } from 'components/Filters';
import NoResults from 'components/NoResults';
import TagPresenter from 'presenters/TagPresenter';
import { isChecked, isIndeterminate, checkTags, checkTagsWithoutParents } from 'utils/TagMenuHelper';
import { toId } from 'utils/strings';

import styles from './styles';

class TagMenu extends PureComponent {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
    placeholderLabel: PropTypes.string,
    type: PropTypes.string,
    classes: PropTypes.shape().isRequired,
    className: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
    onApplyFilter: PropTypes.func,
    clearFilter: PropTypes.func,
    tags: PropTypes.arrayOf(TagPresenter.shape()).isRequired,
    disabled: PropTypes.bool,
    locked: PropTypes.bool,
    divided: PropTypes.bool,
    withoutParents: PropTypes.bool,
    mobile: PropTypes.bool,
    menuHeight: PropTypes.string,
    menuWidth: PropTypes.shape(),
    emptyStateProps: PropTypes.shape(),
  };

  static defaultProps = {
    value: [],
    disabled: false,
    type: 'filter',
    placeholderLabel: null,
    className: '',
    locked: false,
    divided: false,
    withoutParents: false,
    onApplyFilter: () => {},
    clearFilter: () => {},
    mobile: false,
    menuHeight: '',
    menuWidth: {},
    emptyStateProps: {},
  };

  state = {
    searchTerm: '',
    hideFilterList: false,
    close: false,
  };

  handleChangeTag = item => () => {
    const { withoutParents } = this.props;
    return withoutParents ? this.handleChangeItemWithoutParents(item) : this.handleChangeItem(item);
  };

  handleChangeItem = item => {
    const { value, onChange, tags } = this.props;
    const checkedTags = checkTags(tags, item, value);

    return onChange(checkedTags);
  };

  handleChangeItemWithoutParents = item => {
    const { value, onChange } = this.props;
    const checkedTags = checkTagsWithoutParents(item, value);

    return onChange(checkedTags);
  };

  handleChangeSearch = searchTerm => {
    this.setState({ searchTerm });
  };

  renderItem(item, level) {
    const { value, classes } = this.props;
    const menuItemClass = cn(
      {
        [classes.divider]: level === 0,
      },
      classes.menuItem,
    );

    return (
      <ListItem className={menuItemClass} dense={level > 0} key={item.id} data-node="tag-menu-filter-tag-li">
        <label className={classes.name} data-node="tag-menu-filter-tag-label">
          <Checkbox
            checked={isChecked(item, value)}
            indeterminate={isIndeterminate(item, value)}
            onChange={this.handleChangeTag(item)}
            data-node="tag-menu-filter-tag-checkbox"
          />
          {item.name || item.value}
        </label>
        {item.children && item.children.length > 0 && (
          <List className={classes.submenu} data-node="tag-menu-filter-submenu">
            {item.children.map(i => this.renderItem(i, level + 1))}
          </List>
        )}
      </ListItem>
    );
  }

  handleClick(action) {
    this.props.onApplyFilter(action);
    this.setState(
      {
        close: true,
      },
      () => this.setState({ close: false }),
    );
  }

  toggleFilterList() {
    this.setState({
      hideFilterList: !this.state.hideFilterList,
    });
  }

  renderMobileMenu() {
    const { classes, tags, disabled, label, type, locked, divided, mobile, value, menuHeight } = this.props;
    const { searchTerm, hideFilterList, close } = this.state;
    const filteredTags = searchTerm.length > 0 ? TagPresenter.searchTagsByName(flattenTree(tags), searchTerm) : tags;
    const filterClasses = cn(classes.filtersMobileMenu, { [classes.filtersActive]: !isEmpty(value) });
    const contentClass = cn({ [classes.hidden]: hideFilterList }, classes.menu);

    return (
      <>
        {!isEmpty(value) && <div className={cn(classes.filtersCounter, classes.counterPosition)}>{value.length}</div>}
        <FiltersMenu
          buttonProps={{ disabled }}
          className={filterClasses}
          popoverClassName={classes.mobileContent}
          applyFilter={() => this.handleClick()}
          {...{ label, type, locked, divided, mobile, close, menuHeight }}
        >
          <ListItem className={classes.filterHeader}>
            <h1>Filters</h1>
            {!isEmpty(value) && <div className={classes.filtersCounter}>{value.length}</div>}
            <Button
              size="xsmall"
              color="transparent"
              variant="default"
              className={classes.clearFilter}
              onClick={() => this.props.clearFilter()}
            >
              Clear all
            </Button>
          </ListItem>
          <ListItem className={classes.filterContainer}>
            <Button
              onClick={() => this.toggleFilterList()}
              className={classes.filterLabel}
              aria-expanded={!hideFilterList}
              aria-label="Grade Level Filter Menu"
              color="transparent"
              variant="default"
            >
              <h2>Grade Level</h2>
              <Icon className={classes.expand} icon={hideFilterList ? 'expandMore' : 'expandLess'} />
            </Button>
            <List className={contentClass}>{filteredTags.map(item => this.renderItem(item, 0))}</List>
          </ListItem>
          <ListItem className={classes.buttonContainer}>
            <Button
              color="transparent"
              variant="default"
              className={cn(classes.filterForm, classes.cancel)}
              onClick={() => this.handleClick('cancel')}
            >
              Cancel
            </Button>
            <Button
              color="transparent"
              variant="default"
              className={cn(classes.filterForm, classes.apply)}
              onClick={() => this.handleClick('apply')}
            >
              Apply
            </Button>
          </ListItem>
        </FiltersMenu>
      </>
    );
  }

  render() {
    const {
      classes,
      tags,
      disabled,
      label,
      type,
      placeholderLabel,
      locked,
      divided,
      mobile,
      menuHeight,
      menuWidth,
      className,
      emptyStateProps,
    } = this.props;
    const { searchTerm } = this.state;
    const filteredTags = !isEmpty(searchTerm) ? TagPresenter.searchTagsByName(flattenTree(tags), searchTerm) : tags;
    const filterClasses = cn(classes.filtersMenu, { [className]: !!className });
    // since label can be either a string or a component, only give the id if it's a string
    const buttonId = typeof label === 'string' ? `${toId(label)}-menu-button` : null;

    return mobile ? (
      this.renderMobileMenu()
    ) : (
      <FiltersMenu
        buttonProps={compactParams({ disabled, id: buttonId })}
        className={filterClasses}
        popoverClassName={classes.content}
        {...{ label, type, locked, divided, mobile, menuHeight, menuWidth }}
      >
        <ListItem className={classes.searchItem}>
          <FiltersInput
            autoFocus
            value={searchTerm}
            onChangeInput={this.handleChangeSearch}
            startIcon="search"
            placeholder={`Search by ${placeholderLabel || label}`}
            inputProps={{
              'aria-label': `Search ${placeholderLabel || label}`,
              'data-node': 'tag-filter-search-terms',
            }}
            className={classes.searchInput}
            clearable
          />
        </ListItem>
        {filteredTags.length === 0 ? (
          <ListItem className={classes.emptyItem}>
            <NoResults icon="emptyState3" {...{ ...emptyStateProps }} className={classes.emptyState} />
          </ListItem>
        ) : (
          <>{filteredTags.map(item => this.renderItem(item, 0))}</>
        )}
      </FiltersMenu>
    );
  }
}

export default withStyles(styles)(TagMenu);
